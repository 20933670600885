<template>
  <b-card>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Name" label-for="account-name">
            <b-form-input v-model="localUser.fullName" name="name" placeholder="Name" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Role" label-for="account-role">
            <b-form-input v-model="localUser.role" name="role" placeholder="Role" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input v-model="localUser.email" name="email" placeholder="Email" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Level" label-for="account-level">
            <b-form-input v-if="localUser.level" v-model="localUser.level.name" name="level" placeholder="Level" disabled />
            <b-form-input v-else placeholder="No Advisor Assigned" name="advisor" disabled />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label="Advisor" label-for="advisor-name">
            <b-form-input v-if="localUser.advisor" v-model="localUser.advisor.fullName" placeholder="No Advisor Assigned" name="advisor" disabled />
            <b-form-input v-else placeholder="No Advisor Assigned" name="advisor" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Location" label-for="account-location">
            <b-form-input v-model="localUser.location" name="location" placeholder="No Location Provided" disabled />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput
} from 'bootstrap-vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
  },
  props: {
    localUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  watch: {
    localUser: {
      handler(newValue) {
        this.localUser = newValue;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // resetForm() {
    //   this.localUser = JSON.parse(JSON.stringify(this.generalData));
    // },
  },
}
</script>
