<template>
  <b-tabs content-class="col-12 col-md-10 mt-1 mt-md-0" nav-wrapper-class="col-md-2 col-12" nav-class="nav-left" vertical pills>
    <!-- general tab -->
    <b-tab active>
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">General</span>
      </template>
      <account-setting-general v-if="user" :localUser="user" />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Change Password</span>
      </template>
      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  data() {
    return {
      user: null,
    }
  },
  created() {
    this.$http.get('/auth/me').then(res => {
      this.user = res.data;
    }).catch(err => {
      console.error(err);
    });
  },
}
</script>
