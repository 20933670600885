<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form method="POST" class="auth-reset-password-form mt-2" @submit.prevent="handleSubmit">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group label="Old Password" label-for="account-old-password">
              <validation-provider name="Old Password" rules="required">
                <template #default="{ errors }">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="account-old-password" v-model="passwordValueOld" :type="passwordFieldTypeOld"
                      placeholder="Old Password" :state="errors.length > 0 ? false : null" />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </template>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group label="New Password" label-for="account-new-password">
              <validation-provider name="New Password" rules="required|min:8|password">
                <template #default="{ errors }">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="account-new-password" v-model="newPasswordValue" :type="passwordFieldTypeNew"
                      placeholder="New Password" :state="errors.length > 0 ? false : null" />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </template>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group label="Retype New Password" label-for="account-retype-new-password">
              <validation-provider name="Retype New Password" rules="required" ref="retypePasswordProvider">
                <template #default="{ errors }">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="account-retype-new-password" v-model="retypePassword"
                      :type="passwordFieldTypeRetype" placeholder="Retype New Password"
                      :state="errors.length > 0 ? false : null" />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer"
                        @click="togglePasswordRetype" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </template>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" type="submit">
              Save changes
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mt-1"
              @click="resetForm">
              Reset
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations'; // Import other rules if needed
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      retypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password';
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password';
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password';
    },
    handleSubmit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success && this.validatePasswords()) {
          this.$http.put('/auth/me/changePassword', {
            oldPassword: this.passwordValueOld,
            password: this.newPasswordValue,
          }).then(res => {
            this.resetForm();
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Password updated successfully!`,
                icon: 'CheckIcon',
                variant: 'success',
                text: `You have successfully changed your password.`,
              },
            })
          }).catch(err => {
            const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Failed to change password.';
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Password update failed!`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: errorMessage,
              },
            })
            console.error(errorMessage);
          });
        } else {
          console.error('Form validation failed');
        }
      });
    },
    resetForm() {
      this.passwordValueOld = '';
      this.newPasswordValue = '';
      this.retypePassword = '';
      this.passwordFieldTypeOld = 'password';
      this.passwordFieldTypeNew = 'password';
      this.passwordFieldTypeRetype = 'password';
      this.$refs.simpleRules.reset();
    },
    validatePasswords() {
      if (this.newPasswordValue !== this.retypePassword) {
        this.$refs.retypePasswordProvider.setErrors(['Passwords do not match']);
        return false;
      }
      return true;
    },
  },
};
</script>
